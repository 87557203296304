.login-page-left {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
}

.login-page-left::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("./images/logoNonBackgraund.png"),
    linear-gradient(
      to right bottom,
      rgba(240, 147, 251, 0.8),
      rgba(245, 87, 108, 0.8)
    );
  background-repeat: no-repeat;
  background-position: left 50% top 50%, left 0% top 0%;
  background-size: 30% auto, 100% 100%;
}
