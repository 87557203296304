audio {
  width: 1000px;
}

.all {
  width: 150px;
}

hr {
  border: none;
  border-top: 1px dashed #ddd;
  width: 95%;
}
