.loadingWrapper {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(145deg, #1c2022, #212529);
  box-shadow: 10px 10px 20px #625a69, -10px -10px 20px #e1ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  @media screen and (max-width: 375px) {
    width: 12rem;
    height: 12rem;
  }
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 3px solid transparent;
    border-top: 3px solid var(--color-purple-primary);
    border-right: 3px solid var(--color-purple-primary);
    border-left: 3px solid var(--color-gray-secondary);
    border-bottom: 3px solid var(--color-gray-secondary);
    border-radius: 50%;
    // animation: animateCircle 2s linear infinite;
  }
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes smallLoader {
  0% {
    height: 0.5rem;
  }
  40% {
    height: 1rem;
  }
  80% {
    height: 1.2rem;
  }
  100% {
    height: 0.5rem;
  }
}

@keyframes middleLoader {
  0% {
    height: 0.8rem;
  }
  40% {
    height: 2.5rem;
  }
  80% {
    height: 1rem;
  }
  100% {
    height: 0.8rem;
  }
}

@keyframes largeLoader {
  0% {
    height: 1rem;
  }
  40% {
    height: 4rem;
  }
  80% {
    height: 1.2rem;
  }
  100% {
    height: 1rem;
  }
}

.loadingText {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  // テスト用は padding-top:7rem;
}
.border {
  width: 0.5rem;
  height: 0.5rem;
  min-height: 0.5rem;
  max-height: 4rem;
  border-radius: 0.2rem;
  background: var(--color-lightblue-primary);
  &.small {
    animation: loader1 0.8s ease-in-out infinite;
  }
  &.medium {
    animation: loader2 0.8s ease-in-out infinite;
  }
  &.base {
    animation: loader3 0.8s ease-in-out infinite;
  }
  &.big {
    animation: loader4 0.8s ease-in-out infinite;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
  &:nth-child(4) {
    animation-delay: 0.6s;
  }
  &:nth-child(5) {
    animation-delay: 0.8s;
  }
  &:nth-child(6) {
    animation-delay: 1s;
  }
}

@keyframes loader1 {
  0% {
    height: 0.5rem;
  }
  40% {
    height: 0.65rem;
  }
  80% {
    height: 1rem;
  }
  100% {
    height: 0.5rem;
  }
}
@keyframes loader2 {
  0% {
    height: 0.5rem;
  }
  40% {
    height: 0.65rem;
  }
  80% {
    height: 2rem;
  }
  100% {
    height: 0.5rem;
  }
}
@keyframes loader3 {
  0% {
    height: 0.75rem;
  }
  40% {
    height: 1rem;
  }
  80% {
    height: 3rem;
  }
  100% {
    height: 0.75rem;
  }
}
@keyframes loader4 {
  0% {
    height: 1rem;
  }
  40% {
    height: 1.5rem;
  }
  80% {
    height: 4rem;
  }
  100% {
    height: 1rem;
  }
}

.stopborder {
  width: 0.5rem;
  height: 0.5rem;
  min-height: 0.5rem;
  max-height: 4rem;
  border-radius: 0.2rem;
  background: var(--color-lightblue-primary);
}
