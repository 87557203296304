:root {
  --color-dark-primary: #1f2326;
  --color-white-primary: #f4f5f7;
  --color-lightblue-primary: #40c1d2;
  --color-gray-primary: #cfd8dc;
  --color-gray-secondary: #9ea7aa;
  --color-gray-thirdly: #666666;
  --color-purple-primary: #9875f0;
  --color-red-primary: #81322f;
  --color-yellow-primary: #ffd92e;
}
